import { __APP_NAME__ } from "config/static";

export default function Footer() {
  return (
    <div className={`self-end flex justify-center w-full h-16 ${"md:px-2"}`}>
      <footer
        className={`w-full shadow max-w-6xl p-2 py-2.5 mx-auto h-full bg-white md:rounded-t-md dark:bg-gray-800 my-auto`}
      >
        <div
          className={`w-full mx-auto p-4 flex justify-center flex-col h-full md:flex-row items-center md:justify-between`}
        >
          <span className="text-sm font-bold text-gray-500 sm:text-center dark:text-gray-400">
            <a href="/" className="hover:underline">
              {__APP_NAME__}
            </a>
            . All rights reserved. © 2025
          </span>

          <span className="text-sm font-bold text-gray-500 sm:text-center dark:text-gray-400">
            Designed and developed by{" "}
            <a href="/" className="text-orange-500 hover:underline">
              Hometecs
            </a>
          </span>
        </div>
      </footer>
    </div>
  );
}
