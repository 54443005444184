import Alert from "components/Alert/Alert";
import { lazy } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import PublicRoutes from "routes/PublicRoutes";
import "./App.scss";
import PrivateRoutes from "./routes/PrivateRoutes";

const Login = lazy(() => import("./pages/auth/Login/Login"));

function App() {
  const { role } = useSelector((state) => state.auth);

  return (
    <>
      <Routes>
        <Route path="/dashboard/*" element={<PrivateRoutes />} />
        <Route path="/*" element={<PublicRoutes />} />

      </Routes>

      {/* <ThemeToggle /> */}
      <Alert />
    </>
  );
}

export default App;
