import { createSlice } from "@reduxjs/toolkit";


const getUserInfoFromLocal = () => {
  if (sessionStorage.getItem("engInfo"))
    return JSON.parse(sessionStorage.getItem("engInfo"))
  return {}
};

export const engSlice = createSlice({
  name: "eng",
  initialState: getUserInfoFromLocal,
  reducers: {
    add: (state, action) => {

      state.arch_id = action.payload.arch_id;
      state.arch_dgrade = action.payload.arch_dgrade;
      state.arch_ceno = action.payload.arch_ceno;
      state.arch_brno = action.payload.arch_brno;
      state.branch_no = action.payload.branch_no;
      state.branch_name = action.payload.branch_detail.branch_name;
      state.arch_name = action.payload.arch_name;
      state.arch_lname = action.payload.arch_lname;
      state.arch_dbrith = action.payload.arch_dbrith;
      state.arch_djoin = action.payload.arch_djoin;

      sessionStorage.setItem(
        "engInfo",
        JSON.stringify({
          arch_id: action.payload.arch_id,
          arch_dgrade: action.payload.arch_dgrade,
          arch_ceno: action.payload.arch_ceno,
          arch_brno: action.payload.arch_brno,
          branch_no: action.payload.branch_no,
          branch_name: action.payload.branch_detail.branch_name,
          arch_name: action.payload.arch_name,
          arch_lname: action.payload.arch_lname,
          arch_dbrith: action.payload.arch_dbrith,
          arch_djoin: action.payload.arch_djoin,
        })
      );

    }
  },
});

// Action creators are generated for each case reducer function
export const { add } = engSlice.actions;

export default engSlice.reducer;
