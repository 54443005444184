import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateLayoutPages from "components/PrivateLayoutPages/PrivateLayoutPages";
import { Spinner } from "components";
import { useSelector } from "react-redux";
import { Error401, Error404 } from "pages/errors";

const Dashboard = React.lazy(() =>
  import("pages/__PrivatePages/Dashboard/Dashboard")
);
const Settings = React.lazy(() =>
  import("pages/__PrivatePages/Settings/Settings")
);
const Testing = React.lazy(() =>
  import("pages/__PrivatePages/Testing/Testing")
);

const routes = [
  { url: "/", component: <Dashboard />, permission: "view_dashboard" },
  { url: "/settings", component: <Settings /> },
  { url: "/testing", component: <Testing />, permission: "view_testing" },
];

const PrivateRoutes = () => {
  const { role, permissions } = useSelector((state) => state.auth);

  // التحقق من دور المستخدم
  if (role !== "admin") {
    return <Navigate to="/login" replace />;
  }

  // دالة لتحقق من الصلاحية وإرجاع المسار
  const makeRoute = ({ url, component, permission }) => {
    if (permission) {
      // تحقق من الصلاحية
      if (permissions.includes(permission)) {
        return (
          <Route
            key={url}
            path={url}
            element={
              <Suspense fallback={<Spinner page />}>{component}</Suspense>
            }
          />
        );
      }
      // في حال عدم وجود الصلاحية، إعادة توجيه المستخدم إلى صفحة غير مفوض
      else
        return (
          <Route
            key={url}
            path={url}
            element={<Navigate to="/dashboard/not-authorized" replace />}
          />
        );
    } else
      return (
        <Route
          key={url}
          path={url}
          element={<Suspense fallback={<Spinner page />}>{component}</Suspense>}
        />
      );
  };

  return (
    <Routes>
      <Route element={<PrivateLayoutPages />}>
        {routes.map(makeRoute)}
        <Route
          path="/not-authorized"
          element={<Error401 navigateTo={"/"} timer={10000} />}
        />
        <Route path="*" element={<Error404 navigateTo={"/"} timer={10000} />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
