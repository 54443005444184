import { CgMenuLeft } from "react-icons/cg";
import {
  FcImport,
  FcSupport,
  FcTreeStructure,
  FcWorkflow,
} from "react-icons/fc";
import { useSelector } from "react-redux";
import "./Sidebar.scss";
import GroupLinks from "./sidebarCompo/SidebarGroupLinks/SidebarGroupLinks.component";
import { type } from "@testing-library/user-event/dist/type";
import { __APP_NAME__ } from "config/static";

const listLinks = [
  {
    title: "لوحة التحكم",
    url: "/dashboard",
    icon: FcWorkflow,
    role: ["any"],
  },
  {
    title: "ليست",
    url: "/dashboard",
    icon: FcWorkflow,
    role: ["any"],
    type: "list",
    content: [
      {
        title: "عنصر 1",
        url: "/dashboard/#",
        icon: FcWorkflow,
        count: 0,
      },
    ],
  },
  {
    title: "اختبارات",
    url: "/dashboard/testing",
    icon: FcTreeStructure,
    role: ["any"],
  },
];

const listLinks2 = [
  {
    title: "الاعدادات",
    url: "/dashboard/settings",
    icon: FcSupport,
    role: ["any"],
  },
  { title: "تسجيل الحروج", url: "/logout", icon: FcImport, role: ["any"] },
];

export default function Sidebar({ handleStateSidebar }) {
  const sidebarState = useSelector((state) => state.layout.layoutState);

  return (
    <>
      {sidebarState == "openSmall" ? (
        <div
          className="fixed top-0 left-0 z-30 w-full h-full bg-gray-900/50 right-0"
          onClick={() => handleStateSidebar()}
        ></div>
      ) : null}
      <aside
        className={`${sidebarState} fixed top-0 ltr:left-0 rtl:right-0-0 z-40 lg:w-64 md:w-16 w-0 h-full duration-150 sidebar`}
      >
        <div
          className="grid grid-cols-1 h-full md:px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 sidebar-content"
          style={{ gridTemplateRows: "min-content auto min-content" }}
        >
          <div
            className={`w-full flex md:flex-col lg:flex-row items-center justify-between overflow-hidden  sidebar-head`}
          >
            <button
              className="text-xl hover:bg-gray-300 hover:text-white_ rounded-md p-2 duration-200 text-gray-600 dark:text-gray-300 dark:hover:bg-blue-600"
              onClick={handleStateSidebar}
            >
              <CgMenuLeft />
            </button>
            <h1 className="text-4xl font-extrabold mb-1 w-full flex mr-4 ltr:ml-4 items-center gap-3 text-red-600 dark:text-blue-600">
              <div className=" text-gray-700 w-full text-nowrap font-bold dark:text-white">
                <h5>{__APP_NAME__}</h5>
              </div>
            </h1>
          </div>
          <GroupLinks list={listLinks} />
          <GroupLinks list={listLinks2} />
        </div>
      </aside>
    </>
  );
}
