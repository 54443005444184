import { Spinner } from "components";
import PublicLayoutPages from "components/PublicLayoutPages/PublicLayoutPages";
import { Error404 } from "pages/errors";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

const Login = React.lazy(() => import("pages/auth/Login/Login"));
const Booking = React.lazy(() => import("pages/Booking/Booking"));

const routes = [
  { url: "/", component: <Booking /> },
  { url: "/login", component: <Login /> },
  { url: "/booking", component: <Booking /> },
];

const ClientRoutes = () => {
  const { role } = useSelector((state) => state.auth);

  // التحقق من دور المستخدم
  // if (role === "user") {
  //   return <Navigate to="/control-panel" replace />;
  // }

  // if (role === "admin") {
  //   return <Navigate to="/dashboard" replace />;
  // }

  // دالة لتحقق من الصلاحية وإرجاع المسار
  const makeRoute = ({ url, component }) => {
    return (
      <Route
        key={url}
        path={url}
        element={<Suspense fallback={<Spinner page />}>{component}</Suspense>}
      />
    );
  };

  return (
    <Routes>
      <Route element={<PublicLayoutPages />}>
        {routes.map(makeRoute)}
        {/* <Route
          path="/not-authorized"
          element={<Error401 navigateTo={"/"} timer={10000} />}
        /> */}
        <Route path="*" element={<Error404 navigateTo={"/"} timer={10000} />} />
      </Route>
    </Routes>
  );
};

export default ClientRoutes;
