import { configureStore } from "@reduxjs/toolkit";
import layoutSlice from "./reducers/layoutReducer";
import authSlice from "./reducers/authReducer";
import engSlice from "./reducers/engReducer";

const store = configureStore({
  reducer: {
    layout: layoutSlice,
    auth: authSlice,
    engInfo: engSlice,
  },
});

export default store;
